import { useContext, useEffect, useMemo } from "react";
import { DateRange } from "shared/components/DateRangePicker";
import { ISelectOption } from "shared/components/ISelectOption";
import { useRemergeSwrResponse } from "shared/http/RemergeApiSwr";
import { AD_TYPE_VALUES } from "shared/http/apiTypes/ad";
import { RemergeAPIContext } from "shared/http/remergeApiContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { formatDateRangeForAPICall } from "./formatDate";
import { usePageLoadTracking } from "./tracking";
import {
  CategoricalDataItem,
  addPreviousPeriodInventoryData,
  calculateExecutiveSummary,
  calculateTotalsForAdDataTable,
  calculateTotalsForCampaignDataTable,
  fillMissingAdDataWithZeroValues,
  groupInventorydata,
  listAllDaysInTimeseries,
  sortSupplyPartnersBasedOnImpressions,
  transformAllInventoryData,
  transformDataForLineChart,
  transformDruidDataIntoGroupedTimeSeries,
} from "./transformData";

const isNotEmpty = (object: any) => {
  if (!object) return false;

  return Object.keys(object).length > 0;
};

export const useDashboardData = ({
  organizationId,
  selectedAudience,
  setSelectedAudience,
  dateRange,
  prevDateRange,
  currencyCode,
}: {
  organizationId: string;
  selectedAudience: string;
  currencyCode: CurrencyCode;
  setSelectedAudience: (audience: string) => void;
  dateRange: DateRange;
  prevDateRange: DateRange;
}) => {
  const remergeApi = useContext(RemergeAPIContext);

  const { startDate: startDateFormatted, endDate: endDateFormatted } = formatDateRangeForAPICall(dateRange);

  const { startDate: previousStartDateFormatted, endDate: previousEndDateFormatted } =
    formatDateRangeForAPICall(prevDateRange);

  const overviewDruidData = useRemergeSwrResponse(
    startDateFormatted &&
      endDateFormatted &&
      remergeApi.clientDashboardOverviewRequest(organizationId, startDateFormatted, endDateFormatted),
  );

  const previousOverviewDruidData = useRemergeSwrResponse(
    previousStartDateFormatted &&
      previousEndDateFormatted &&
      remergeApi.clientDashboardOverviewRequest(organizationId, previousStartDateFormatted, previousEndDateFormatted),
  );

  const campaignDruidData = useRemergeSwrResponse(
    remergeApi.clientDashboardCampaignBreakdownRequest(organizationId, startDateFormatted!, endDateFormatted!),
  );

  const previousCampaignDruidData = useRemergeSwrResponse(
    remergeApi.clientDashboardCampaignBreakdownRequest(
      organizationId,
      previousStartDateFormatted!,
      previousEndDateFormatted!,
    ),
  );

  const inventoryDruidData = useRemergeSwrResponse(
    remergeApi.clientDashboardInventoryBreakdownRequest(organizationId, startDateFormatted!, endDateFormatted!),
  );
  const previousInventoryDruidData = useRemergeSwrResponse(
    remergeApi.clientDashboardInventoryBreakdownRequest(
      organizationId,
      previousStartDateFormatted!,
      previousEndDateFormatted!,
    ),
  );

  const adTypeDruidData = useRemergeSwrResponse(
    remergeApi.clientDashboardAdTypeBreakdownRequest(organizationId, startDateFormatted!, endDateFormatted!),
  );

  usePageLoadTracking(organizationId, overviewDruidData, campaignDruidData, inventoryDruidData, adTypeDruidData);

  const uniqueAudiences = useMemo(
    () =>
      campaignDruidData.data
        ? campaignDruidData.data.data.reduce<Set<string>>((acc, cur) => {
            acc.add(cur.event.audience);
            return acc;
          }, new Set())
        : new Set<string>(),
    [campaignDruidData.data],
  );

  const audienceOptions: ISelectOption[] = [...uniqueAudiences].map(audience => ({ label: audience, value: audience }));

  const totals = overviewDruidData.data && calculateExecutiveSummary(overviewDruidData.data);
  const previousTotals = previousOverviewDruidData.data && calculateExecutiveSummary(previousOverviewDruidData.data);

  useEffect(() => {
    if (uniqueAudiences.size > 0 && campaignDruidData.data) {
      const audiencesByEntries = [...uniqueAudiences]
        .map(audience => {
          return {
            audience,
            entries: campaignDruidData.data!.data.filter(item => item.event.audience === audience).length,
          };
        })
        .sort((a, b) => b.entries - a.entries);

      setSelectedAudience(audiencesByEntries[0].audience);
    }
  }, [organizationId, uniqueAudiences, setSelectedAudience, campaignDruidData.data]);

  const campaignDruidDataForSelectedAudience = campaignDruidData.data
    ? campaignDruidData.data.data.filter(item => item.event.audience === selectedAudience)
    : undefined;

  const previousCampaignDruidDataForSelectedAudience = previousCampaignDruidData.data
    ? previousCampaignDruidData.data.data.filter(item => item.event.audience === selectedAudience)
    : undefined;

  const groupedCampaignTimeSeries = campaignDruidDataForSelectedAudience
    ? transformDruidDataIntoGroupedTimeSeries(campaignDruidDataForSelectedAudience)
    : undefined;

  const allDaysInTimeSeries = campaignDruidDataForSelectedAudience
    ? listAllDaysInTimeseries(campaignDruidDataForSelectedAudience)
    : undefined;

  const filteredAdTypeDruidData = adTypeDruidData.data
    ? {
        data: adTypeDruidData.data.data.filter(item => AD_TYPE_VALUES.includes(item.event.ad_type)),
      }
    : undefined;

  const groupedAdTypeTimeSeries = filteredAdTypeDruidData
    ? transformDruidDataIntoGroupedTimeSeries(filteredAdTypeDruidData.data)
    : undefined;

  const completeGroupedAdTypeTimeSeries =
    groupedAdTypeTimeSeries && allDaysInTimeSeries
      ? fillMissingAdDataWithZeroValues(groupedAdTypeTimeSeries, allDaysInTimeSeries)
      : undefined;

  const adTypeApConversionsSeries = completeGroupedAdTypeTimeSeries
    ? transformDataForLineChart(completeGroupedAdTypeTimeSeries, "ap_conversions")
    : undefined;

  const adTypeSpendSeries = completeGroupedAdTypeTimeSeries
    ? transformDataForLineChart(completeGroupedAdTypeTimeSeries, "buying_price")
    : undefined;

  const transformedInventoryData = inventoryDruidData.data
    ? transformAllInventoryData(inventoryDruidData.data)
    : undefined;

  const previousTransformedInventoryData = previousInventoryDruidData.data
    ? transformAllInventoryData(previousInventoryDruidData.data)
    : undefined;

  const top10SupplyPartners =
    isNotEmpty(transformedInventoryData) && isNotEmpty(previousTransformedInventoryData)
      ? transformedInventoryData!["impressions"]
          .sort((a: CategoricalDataItem, b: CategoricalDataItem) => b.y - a.y)
          .slice(0, 10)
          .map((val: CategoricalDataItem) => val.x)
      : undefined;

  const groupedInventoryData =
    transformedInventoryData && top10SupplyPartners
      ? groupInventorydata(transformedInventoryData, top10SupplyPartners)
      : undefined;

  const previousGroupedInventoryData =
    previousTransformedInventoryData && inventoryDruidData.data && top10SupplyPartners
      ? groupInventorydata(previousTransformedInventoryData, top10SupplyPartners)
      : undefined;

  const sortedInventoryData = groupedInventoryData
    ? sortSupplyPartnersBasedOnImpressions(groupedInventoryData)
    : undefined;

  const inventorySeriesValues =
    sortedInventoryData && previousGroupedInventoryData
      ? addPreviousPeriodInventoryData(sortedInventoryData, previousGroupedInventoryData)
      : undefined;

  const campaignDataForTable =
    campaignDruidDataForSelectedAudience && previousCampaignDruidDataForSelectedAudience
      ? calculateTotalsForCampaignDataTable(
          { data: campaignDruidDataForSelectedAudience },
          { data: previousCampaignDruidDataForSelectedAudience },
          currencyCode,
        )
      : [];

  const adTypeAPConversionsData = filteredAdTypeDruidData
    ? calculateTotalsForAdDataTable(filteredAdTypeDruidData, "ap_conversions", currencyCode)
    : [];

  const adTypeBuyingPriceData = filteredAdTypeDruidData
    ? calculateTotalsForAdDataTable(filteredAdTypeDruidData, "buying_price", currencyCode)
    : [];

  return {
    totals,
    previousTotals,
    inventorySeriesValues,
    audienceOptions,
    uniqueAudiences,
    groupedCampaignTimeSeries,
    campaignDataForTable,
    adTypeApConversionsSeries,
    adTypeAPConversionsData,
    adTypeSpendSeries,
    adTypeBuyingPriceData,
  };
};
