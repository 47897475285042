import { Button, ButtonProps, styled } from "@mui/material";
import { forwardRef, useContext } from "react";
import { I18nContext } from "shared/utilities/I18nContext";
import { DateRange } from "../DateRangePicker";
import CalendarIcon from "../icons/CalendarIcon";

export type DateRangeButtonProps = {
  dateRange: DateRange;
  size?: ButtonProps["size"];

  onClick: ButtonProps["onClick"];
};

const DateRangeButton = forwardRef<HTMLButtonElement, DateRangeButtonProps>(({ dateRange, size, onClick }, ref) => {
  const i18n = useContext(I18nContext);

  const hasText = Boolean(dateRange.startDate || dateRange.endDate);

  return (
    <Root hasText={hasText} size={size} variant="naked" startIcon={<CalendarIcon />} onClick={onClick} ref={ref}>
      {i18n.formatDateRange(dateRange)}
    </Root>
  );
});

const Root = styled(Button, { shouldForwardProp: prop => prop !== "hasText" })(({ hasText }: { hasText: boolean }) => ({
  px: 1,
  textTransform: "none",

  "& .MuiButton-startIcon": {
    marginRight: hasText ? 10 : 0,
  },
}));

export default DateRangeButton;
