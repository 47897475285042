import { forwardRef } from "react";
import DateRangeButton from "./DateRangeButton/DateRangeButton";
import DateRangePicker, { DateRange } from "./DateRangePicker";

export type MaterialDateRangePickerProps = {
  value: DateRange;
  onChange(range: DateRange): void;
  disableFutureDates?: boolean;
  okButtonTitle?: string;
  renderTriggerButton?: ButtonRenderer;
};

type ButtonRenderer = (dateRange: DateRange, onClick: () => void) => JSX.Element;

function MaterialDateRangePicker({
  value: { startDate, endDate },
  onChange,
  disableFutureDates,
  okButtonTitle,
  renderTriggerButton,
}: MaterialDateRangePickerProps) {
  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onClose={() => {}}
      onChange={onChange}
      disableFutureDates={disableFutureDates}
      customInput={
        <CustomTriggerButton
          onClick={() => {}}
          startDate={startDate}
          endDate={endDate}
          renderTriggerButton={renderTriggerButton}
        />
      }
      okButtonTitle={okButtonTitle}
    />
  );
}

type CustomTriggerButtonProps = {
  onClick: () => void;
  startDate: Date | undefined;
  endDate: Date | undefined;
  renderTriggerButton?: ButtonRenderer;
};

const CustomTriggerButton = forwardRef<HTMLButtonElement, CustomTriggerButtonProps>(
  ({ onClick, startDate, endDate, renderTriggerButton }, ref) => {
    if (renderTriggerButton) {
      return renderTriggerButton({ startDate, endDate }, onClick);
    }

    return <DateRangeButton ref={ref} dateRange={{ startDate, endDate }} size="small" onClick={onClick} />;
  },
);

CustomTriggerButton.displayName = "CustomTriggerButton";

export default MaterialDateRangePicker;
