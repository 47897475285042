import { useEffect, useState } from "react";
import { DateRange } from "shared/components/DateRangePicker";
import { formatDateRangeForAPICall } from "./formatDate";
import { subtractNdays } from "./formatShortRange";

export default function useDateRange() {
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const startDateFromUrl = queryParams.get("startDate");
    const endDateFromUrl = queryParams.get("endDate");

    if (startDateFromUrl && endDateFromUrl)
      return { startDate: new Date(startDateFromUrl), endDate: new Date(endDateFromUrl) };

    const today = new Date();
    today.setUTCDate(today.getUTCDate() - 1);
    today.setUTCHours(0, 0, 0, 0);

    const oneWeekAgo = new Date();
    oneWeekAgo.setUTCDate(oneWeekAgo.getUTCDate() - 7);
    oneWeekAgo.setUTCHours(0, 0, 0, 0);
    return { startDate: oneWeekAgo, endDate: today };
  });

  const onDateRangeChange = (newDateRange: DateRange) => {
    setDateRange(prevDateRange => ({
      startDate: newDateRange.startDate || prevDateRange.startDate,
      endDate: newDateRange.endDate || prevDateRange.endDate,
    }));
  };

  const { startDate, endDate } = formatDateRangeForAPICall(dateRange);
  useEffect(() => {
    if (!startDate || !endDate) return;

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("startDate", startDate);
    queryParams.set("endDate", endDate);
    history.replaceState(null, "", "?" + queryParams.toString());
  }, [startDate, endDate]);

  const diffInDays =
    dateRange.startDate && dateRange.endDate
      ? (dateRange.endDate?.getTime() - dateRange.startDate?.getTime()) / (1000 * 60 * 60 * 24)
      : undefined;

  const daysAgoPreviousPeriodStarts = 1 + diffInDays!;
  const prevDateRange = {
    startDate: subtractNdays(dateRange.startDate!, daysAgoPreviousPeriodStarts),
    endDate: subtractNdays(dateRange.startDate!, 1),
  };

  return {
    dateRange,
    prevDateRange,
    onDateRangeChange,
  };
}
