import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, styled, useMediaQuery } from "@mui/material";
import theme from "@shared/utilities/theme";
import { useContext, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { I18nContext, i18n } from "shared/utilities/I18nContext";
import Button from "./Button";

export type DateRange = { startDate: Date | undefined; endDate: Date | undefined };

export type Props = {
  onChange(range: DateRange): void;
  startDate: DateRange["startDate"];
  endDate: DateRange["endDate"];
  okButtonTitle?: string;
  onClose: () => void;
  disableFutureDates?: boolean;
  customInput: React.ReactElement | undefined;
};

function DateRangePicker({
  onChange,
  onClose,
  okButtonTitle,
  customInput,
  disableFutureDates,
  startDate: initialStartDate,
  endDate: initialEndDate,
}: Props) {
  const i18n = useContext(I18nContext);
  const { translations: t } = i18n;

  const [startDate, setStartDate] = useState<DateRange["startDate"]>(initialStartDate);
  const [endDate, setEndDate] = useState<DateRange["endDate"]>(initialEndDate);

  const datePickerRef = useRef<ReactDatePicker>(null);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <CustomDatePickerWrapper isDesktop={isDesktop}>
      <ReactDatePicker
        ref={datePickerRef}
        selected={startDate}
        onChange={updateDateRange}
        startDate={stripTimeFromDate(startDate)}
        endDate={stripTimeFromDate(endDate)}
        selectsRange
        monthsShown={2}
        customInput={customInput}
        shouldCloseOnSelect={false}
        maxDate={disableFutureDates ? new Date() : undefined}
        withPortal
        renderCustomHeader={props => <CustomHeader {...props} />}
      >
        <ActionsWrapper>
          <Button variant="text" onClick={resetDateRange}>
            {t.common.cancelAction}
          </Button>

          <Button variant="contained" color="primary" onClick={applySelectedDateRange}>
            {okButtonTitle || t.common.okAction}
          </Button>
        </ActionsWrapper>
      </ReactDatePicker>
    </CustomDatePickerWrapper>
  );

  function stripTimeFromDate(input: Date | undefined): Date | undefined {
    if (!input) return;

    return new Date(input.toISOString().split("T")[0]);
  }

  function updateDateRange(dates: [Date | null, Date | null] | null) {
    if (!dates) return;
    const [start, end] = dates;

    setStartDate(start ?? undefined);
    setEndDate(end ?? undefined);
  }

  function applySelectedDateRange() {
    onChange({ startDate, endDate });

    datePickerRef.current?.setOpen(false);
  }

  function resetDateRange() {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);

    datePickerRef.current?.setOpen(false);
    onClose();
  }
}

type CustomHeaderProps = {
  monthDate: Date;
  customHeaderCount: number;

  decreaseMonth: () => void;
  increaseMonth: () => void;
};

function CustomHeader({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }: CustomHeaderProps) {
  return (
    <HeaderWrapper>
      <NextPrevMonthButton
        aria-label="Previous Month"
        onClick={decreaseMonth}
        disabled={customHeaderCount === 1}
        variant="text"
        hidden={customHeaderCount === 1}
      >
        <ChevronLeft />
      </NextPrevMonthButton>

      <MonthLabel>{i18n.formatDateTime(monthDate, "monthAndYear")}</MonthLabel>

      <NextPrevMonthButton
        aria-label="Next Month"
        onClick={increaseMonth}
        disabled={customHeaderCount === 0}
        hidden={customHeaderCount === 0}
        variant="text"
      >
        <ChevronRight />
      </NextPrevMonthButton>
    </HeaderWrapper>
  );
}

const CustomDatePickerWrapper = styled("div")<{ isDesktop: boolean }>(({ theme, isDesktop }) => ({
  "& .react-datepicker__day--selected, & .react-datepicker__day--in-range, & .react-datepicker__day--keyboard-selected":
    {
      backgroundColor: theme.palette.primary.light + " !important",
      color: theme.palette.common.white + " !important",
    },

  "& .react-datepicker__day--selecting-range, & .react-datepicker__day--selecting-range-start, & .react-datepicker__day--selecting-range-end":
    {
      backgroundColor: theme.palette.primary.light + " !important",
      color: theme.palette.common.white + " !important",
    },

  ...(isDesktop && {
    "& .react-datepicker__children-container": {
      marginLeft: "auto",
      width: "auto",
    },
  }),
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
  padding: theme.spacing(0.5),
}));

const HeaderWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const NextPrevMonthButton = styled(Button)(({ hidden }) => ({
  visibility: hidden ? "hidden" : "visible",
  minWidth: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const MonthLabel = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.typography.h6.fontSize,
  textAlign: "center",
}));

export default DateRangePicker;
